import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    background: #000;
    color: white;
    font-family: Arial;
  }

  a {
    color: white;
  }

`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <GlobalStyle/>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'AMNESIA SCANNER - VAULT' },
            { name: 'keywords', content: 'amensia scanner, vault, another life' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        {children}
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
